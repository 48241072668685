<template>
  <section class="policy-list">
    <div class="flex-sb-center">
      <v-header title="Transaction"></v-header>
      <v-button @click="handleExport">Export</v-button>
    </div>
    <div>
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick">
        <el-tab-pane
          label="Record"
          name="record"></el-tab-pane>
        <!--        <el-tab-pane-->
        <!--          label="Reports"-->
        <!--          name="Reports"></el-tab-pane>-->
      </el-tabs>
      <div>
        <span
          v-if="activeName=='record'"
          class="tip">Filter by issue date or effective date to see statistics.</span>
      </div>
      <div>
        <record
          v-if="activeName=='record'"
          ref="record"
        ></record>
        <!--        <reports-->
        <!--          v-else-->
        <!--          :type="3"></reports>-->
      </div>
    </div>

  </section>
</template>

<script>
import record from '@/views/account/transactions/record'
import reports from '@/views/member/componment/reports'


import {getTransactionList} from '@/api/transcation'
export default {
  name: 'transaction',
  components:{
    // reports,
    record
  },
  data(){
    return {
      activeName:'record',
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      tableData: []
    }
  },
  created(){
    // this.network().getTransactionList()
  },
  methods: {
    handleClick() {

    },
    handleExport(){
      this.$refs.record.handleExport()
    },
    rowClick() {
      this.$router.push('/member/referral/statement')
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
::v-deep .table-slot-header{
  height: 71px!important;
}
</style>
